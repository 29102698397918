import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import {AngularMaterialModule} from '../../../app-module/angular-matrial.module';
import {InvoiceCalculationComponent} from './invoiceCalculation.component';
import {InvoiceCalculationRoutingModule} from './invoiceCalculation-routing.module';
import {AutoCodeGeneratorModule} from '../invoice-code-generator/invoice-code-generator.module';

@NgModule({
    imports: [CommonModule, InvoiceCalculationRoutingModule, AutoCodeGeneratorModule, AngularMaterialModule],
    declarations: [InvoiceCalculationComponent]
})
export class InvoiceCalculationModule {}
