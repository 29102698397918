//import {AdGenralDtlMst} from "./AddGenralDetail";


export class LedgerMst{
    
    ledId:string;
    ledCode:string;
    ledType:string;
    ledName:string;
    ledUnderGrp:string;
    openingBal:number;
    opnBalType:string;
    ledHolderName:string;
    ledGST:string;
    ledState:string;
    ledCity:string;
    ledHashTag:string;
    defaultLed: boolean;
    
    // for add bank detail in Ledger
    bankDate:string;
    bankName:string;
    bankAccHolderName:string;
    bankAccType:string;
    bankBranch:string;
    bankAccNo:string;
    bankOpeningBal:number;
    bankIfscCode:string;  
    type:string;
    
    crDt: Date;
    mdDate: Date;
    orgId: string;
    defunct: boolean;
    oprId:string;
    crBy:string;
    mdBy:string;
}