/* 
 * To change this license header, choose License Headers in Project Properties.
 * To change this template file, choose Tools | Templates
 * and open the template in the editor.
 */
export class SignUpRequest {

    id: string = "";
    orgId: string;
    oprId: string;
    username: string = "";
    bussinessName: string = "";
    userMobileNo: string = "";
    email: string = "";
    password: string = "";
    c_password: string = "";
    userType: string = "";
//    userGstNum:string="";
     manageAccount:string[]=[];

    userLegalName: string = "";
    firstName: string = "";
    lastName: string = "";
   
   crBy: string= "";
    mdBy: string= "";
    url: string= "";
    companyAddress: string= "";
    state: string= "";
    city: string= "";
    zipCode: number;
    gstNo: string= "";
    iecNo: number;
    panNo: string= "";
    msmeNo: string= "";
    stateCode: string;
    bankDetails: string= "";
    phoneNo: number;
    fiscalYear: string= "";
    usrGstTreatment: string= "";

}


