import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import {AngularMaterialModule} from '../../../app-module/angular-matrial.module';
import {EmailComponentComponent} from './EmailComponent.component';
import {EmailComponentRoutingModule} from './EmailComponent-routing.module';
@NgModule({
    imports: [CommonModule, EmailComponentRoutingModule,AngularMaterialModule],
    declarations: [EmailComponentComponent]
})
export class EmailComponentModule {}
